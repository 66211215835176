<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1"></div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content">
          <div style="margin: 30px 0">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/news' }">资讯</el-breadcrumb-item>
              <el-breadcrumb-item>{{ news.title }}</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="newsInfo_title">{{ news.title }}</div>
          <div class="newsInfo_content" v-html="news.content"></div>
          <div style="width: 95%; margin: 30px auto 100px auto">
            <div class="news_bottom" style="float: left" @click="goPreNews">
              <i class="el-icon-arrow-left"></i>前一个：
              <template v-if="preNews != null">{{ preNews.title }}</template>
              <template v-if="!preNews">无</template>
            </div>
            <div class="news_bottom" style="float: right" @click="goNextNews">
              <i class="el-icon-arrow-right"></i>后一个：
              <template v-if="nextNews != null">{{ nextNews.title }}</template>
              <template v-if="!nextNews">无</template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import http from "@/api/front";
export default {
  data() {
    return {
      query: { id: null },
      news: {},
      preNews: null,
      nextNews: null,
    };
  },
  mounted() {
    this.query.id = this.$route.query.id;
    this.getNewsInfo();
  },
  methods: {
    goPreNews() {
      if (this.preNews) {
        location.href = "/news/info?id=" + this.preNews.id;
      }
    },
    goNextNews() {
      if (this.nextNews) {
        location.href = "/news/info?id=" + this.nextNews.id;
      }
    },
    getNewsInfo() {
      http.getNewsInfo(this.query, (res) => {
        if (res.success) {
          this.news = res.data;
          document.title = document.title.replace("行业资讯", this.news.title);
          http.getNewsInfoAround(this.query, (res) => {
            if (res.success) {
              this.preNews = res.data.preNews;
              this.nextNews = res.data.nextNews;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "news.scss";
/deep/ .el-breadcrumb__inner.is-link {
  font-weight: normal;
  &:hover {
    color: #13a580;
  }
}

.newsInfo_title {
  padding: 0;
  margin: 60px 0 10px 0;
  width: 100%;
  height: auto;
  font-family: "Noto Sans CJK";
  color: #404040;
  font-size: 28px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
  word-wrap: break-word;
  word-break: normal;
}
.newsInfo_content {
  padding: 20px 20px;
  width: 100%;
  height: auto;
  font-family: "Microsoft YaHei";
  color: #404040;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  text-align: left;
  line-height: 24px;
  word-wrap: break-word;
  word-break: normal;
}
.news_bottom {
  height: auto;
  width: fit-content;
  font-family: Tahoma;
  font-size: 12px;
  color: #666;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  line-height: 24px;
  &:hover {
    cursor: pointer;
    color: #13a580;
  }
}
</style>
